// Fonts
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

$font1: "Montserrat", sans-serif;
$font2: "Abril Fatface", serif;

// Transitions
$transition1: 0.3s cubic-bezier(0.19, 1, 0.22, 1);

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

body, html {
    scroll-behavior: smooth;
}

#root {
    width: 100%;
    overflow: hidden;
    background: linear-gradient(#272727, #181818);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 90%;
        background-color: rgba(0,0,0,0.95);
        backdrop-filter: blur(3px);
        position: fixed;
        z-index: 2;
        top: 2vh;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        padding: 15px;
        max-width: 1200px;
    }

    h2 {
        font-family: $font1;
        font-size: clamp(2.4rem, 7vw, 3.2rem);
        text-align: center;
    }

    .block {
        padding: 100px 7vw 0px;
        margin: auto;
        max-width: 1200px;
        width: 100%;

    }

    h3 {
        background: #E25858;
        background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $font1;
        font-size: 2.3rem;

    }
}

// Nav

nav > a {
    color: #fff;
    font-family: $font1;
    font-size: 1.2rem;
    letter-spacing: -1px;
    transition: $transition1;


    &:hover {
        letter-spacing: 1px;
        background: #E25858;
        background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


nav > button {
    font-size: 2rem;
    display: flex;
    align-items: center;
    color: #fff;
    background: none;
    border: none;
    transition: $transition1;
    
    &:hover {
        cursor: pointer;
        font-size: 2.2rem;
    }
}

#navList {
    position: fixed;
    left: -100%;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.95);
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-family: $font1;
    color: #fff;
    font-size: 1.5rem;
    padding: 20vh 5vw;
    transition: $transition1;

    button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 4rem;
        border: none;
        background: none;
        filter: invert(1);
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    li {

        a {
            color: #fff;
            transition: $transition1;

            &:hover {
                letter-spacing: 2px;
                background: #E25858;
                background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

    }
}

.open{
    left: 0px !important;
}

// Hero

#hero {
        font-family: $font1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20vh 7vw;
        text-align: center;
        position: relative;
        max-width: 1200px;
        margin: auto;
        width: 100%;
        height: 100vh;

        .hero-icon {
            opacity: 0.1;
            position: absolute;
            font-size: 50rem;
        }

        h1 {
            position: relative;
            z-index: 1;
            font-size: clamp(3rem, 10vw, 5.3rem);
        }

        h2 {
            font-family: $font2;
            position: relative;
            z-index: 0;
            font-size: clamp(2rem, 7vw, 3.7rem);
            background: #E25858;
            background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

}

// Header

#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    // grid-template-columns: 1fr;
    // grid-template-rows: 1fr 1fr;


    .about-text {

        height: fit-content;

        p {
            text-align: justify;
            font-family: $font1;
            font-weight: 400;
            margin: 40px 0px;
            letter-spacing: 1px;
            line-height: 30px;
            font-size: 1rem;
        }
    }

    .about-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 300px;
    }
}

// Skills

#skills {

    h2 {
        margin-bottom: 40px;
    }

    .skill-box {
        margin-bottom: 60px;

        h3 {
            background: #E25858;
            background: linear-gradient(to right, #ff5252 30%, #A44CDA 80%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            text-align: justify;
            font-family: $font1;
            font-weight: 400;
            margin: 40px 0px;
            letter-spacing: 1px;
            line-height: 30px;
            font-size: 1rem;
        }
    }

    .skill-level {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
            font-family: $font1;
            width: 100px;
            font-size: clamp(0.9rem, 3vw, 1.2rem);
            margin: 10px 0px;
        }

        progress {
            /* Couleurs et bordures */
            background-color: #fff;
            border: 0;
            border-radius: 20px;
            height: 7px;
            width: 200px;
          }

        progress::-webkit-progress-bar {
            background-color: #000000;
            border-radius: 20px;
        }

        progress::-moz-progress-bar {
            background-color: #ff5252;
        }
        
        progress::-webkit-progress-value {
            background-color: #04AA6D;
            border-radius: 20px;
        }
    }

}

// Projects

#projects {

    h2 {
        margin-bottom: 50px;
    }
    
    .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0px;

        h3 {
            margin: 40px 0px;
        }

        .project-image {
            width: 250px;
            height: 250px;
            background-position: center;
            background-size: cover;
            transition: $transition1;

            &:hover {
                cursor: pointer;
                filter: brightness(0.6);
                scale: 1.1;
            }
        }

        .thomacon {
            background-image: url("../img/thomacon.png");
        }

        .quai-antique {
            background-image: url("../img/quai-antique.png");
        }

        .garage-parrot {
            background-image: url("../img/garage.png");
        }

        p {
            text-align: justify;
            font-family: $font1;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 30px;
            font-size: 1rem;
            margin-bottom: 40px;
        }

        a {
            color: #fff;
            font-family: $font1;
            font-weight: 600;
            font-size: 1.5rem;
            border: 2px solid #fff;
            padding: 10px 20px;
            transition: $transition1;
            position: relative;
            z-index: 1;
            overflow: hidden;

            &:hover {
                cursor: pointer;
                color: #ff5252;
            }

            &::before {
                content: "";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -2px;
                left: -106px;
                background: #fff;
                width: 106px;
                height: 53px;
                transition: $transition1;
                z-index: 0;
            }

            &:hover::before {
                left: 0px;
                z-index: -1;
            }
        }

    }
}

// Contact me

#contactMe {
    h2 {
        margin-bottom: 40px;    
    }
    p {
        text-align: justify;
        font-family: $font1;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 30px;
        font-size: 1rem;
        margin-bottom: 40px;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 600px;

        input, textarea {
            background: none;
            border: 1px solid #fff;
            font-family: $font1;
            font-size: 1.3rem;
            padding: 10px;
            margin: 20px 0px;
            color: #fff;

            &:focus {
                outline: none;
            }
        }

        textarea {
            min-height: 200px;
        }
        
        button {
            color: #fff;
            font-family: $font1;
            font-weight: 600;
            width: fit-content;
            background: none;
            font-size: 1.3rem;
            padding: 10px 20px;
            border: 1px solid #fff;
            transition: $transition1;
            text-transform: uppercase;

            &:hover {
                cursor: pointer;
                color: #ff5252;
                background: #fff;
            }
        }
    }
}

// Footer
footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
    font-family: $font1;
    text-align: center;
}

@media screen and (min-width: 850px) {
    #skills {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 100px 1fr;

        h2 {
            grid-row: 1/2;
            grid-column: 2/4;
        }

        .skill-box {
            padding: 0px 20px;

            h3 {
                text-align: center;
            }

            p {
                height: 250px;
            }
        }

        .skill-level {
            justify-content: center;

            h4 {
                margin-right: 20px;
            }
        }

        .box1, .box2 {
            grid-row: 2/3;
        }

        .box1 {
            grid-column: 1/3;
        }

        .box2 {
            grid-column: 3/5;
        }
    }
}

@media screen and (min-width: 1200px) {

    .block {
        padding: 100px 10px 0px !important;
    }

    #projects {
        display: grid;
        grid-template-rows: 100px 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        h2 {
            grid-row: 1/2;
            grid-column: 1/4;
        }

        .project {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 250px 100px 250px;
            align-items: center;
            justify-items: center;
            padding: 0px 20px;

            h3 {
                text-align: center;
            }

            .project-image {
                width: 200px;
                height: 200px;
            }

            p {
                align-self: flex-start;
            }

        }
    }

}