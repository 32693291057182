@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body, html {
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(#272727, #181818);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
#root nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  position: fixed;
  z-index: 2;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 15px;
  max-width: 1200px;
}
#root h2 {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(2.4rem, 7vw, 3.2rem);
  text-align: center;
}
#root .block {
  padding: 100px 7vw 0px;
  margin: auto;
  max-width: 1200px;
  width: 100%;
}
#root h3 {
  background: #E25858;
  background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 2.3rem;
}

nav > a {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  letter-spacing: -1px;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
nav > a:hover {
  letter-spacing: 1px;
  background: #E25858;
  background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

nav > button {
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  background: none;
  border: none;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
nav > button:hover {
  cursor: pointer;
  font-size: 2.2rem;
}

#navList {
  position: fixed;
  left: -100%;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 1.5rem;
  padding: 20vh 5vw;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
#navList button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 4rem;
  border: none;
  background: none;
  filter: invert(1);
  display: flex;
  align-items: center;
}
#navList button:hover {
  cursor: pointer;
}
#navList li a {
  color: #fff;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
#navList li a:hover {
  letter-spacing: 2px;
  background: #E25858;
  background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.open {
  left: 0px !important;
}

#hero {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20vh 7vw;
  text-align: center;
  position: relative;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  height: 100vh;
}
#hero .hero-icon {
  opacity: 0.1;
  position: absolute;
  font-size: 50rem;
}
#hero h1 {
  position: relative;
  z-index: 1;
  font-size: clamp(3rem, 10vw, 5.3rem);
}
#hero h2 {
  font-family: "Abril Fatface", serif;
  position: relative;
  z-index: 0;
  font-size: clamp(2rem, 7vw, 3.7rem);
  background: #E25858;
  background: linear-gradient(to right, #ff5252 30%, #A44CDA 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#about {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#about .about-text {
  height: -moz-fit-content;
  height: fit-content;
}
#about .about-text p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 40px 0px;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 1rem;
}
#about .about-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

#skills h2 {
  margin-bottom: 40px;
}
#skills .skill-box {
  margin-bottom: 60px;
}
#skills .skill-box h3 {
  background: #E25858;
  background: linear-gradient(to right, #ff5252 30%, #A44CDA 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#skills .skill-box p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 40px 0px;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 1rem;
}
#skills .skill-level {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#skills .skill-level h4 {
  font-family: "Montserrat", sans-serif;
  width: 100px;
  font-size: clamp(0.9rem, 3vw, 1.2rem);
  margin: 10px 0px;
}
#skills .skill-level progress {
  /* Couleurs et bordures */
  background-color: #fff;
  border: 0;
  border-radius: 20px;
  height: 7px;
  width: 200px;
}
#skills .skill-level progress::-webkit-progress-bar {
  background-color: #000000;
  border-radius: 20px;
}
#skills .skill-level progress::-moz-progress-bar {
  background-color: #ff5252;
}
#skills .skill-level progress::-webkit-progress-value {
  background-color: #04AA6D;
  border-radius: 20px;
}

#projects h2 {
  margin-bottom: 50px;
}
#projects .project {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}
#projects .project h3 {
  margin: 40px 0px;
}
#projects .project .project-image {
  width: 250px;
  height: 250px;
  background-position: center;
  background-size: cover;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
#projects .project .project-image:hover {
  cursor: pointer;
  filter: brightness(0.6);
  scale: 1.1;
}
#projects .project .thomacon {
  background-image: url("../img/thomacon.png");
}
#projects .project .quai-antique {
  background-image: url("../img/quai-antique.png");
}
#projects .project .garage-parrot {
  background-image: url("../img/garage.png");
}
#projects .project p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 1rem;
  margin-bottom: 40px;
}
#projects .project a {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  border: 2px solid #fff;
  padding: 10px 20px;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#projects .project a:hover {
  cursor: pointer;
  color: #ff5252;
}
#projects .project a::before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  left: -106px;
  background: #fff;
  width: 106px;
  height: 53px;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 0;
}
#projects .project a:hover::before {
  left: 0px;
  z-index: -1;
}

#contactMe h2 {
  margin-bottom: 40px;
}
#contactMe p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 1rem;
  margin-bottom: 40px;
}
#contactMe form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
#contactMe form input, #contactMe form textarea {
  background: none;
  border: 1px solid #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  padding: 10px;
  margin: 20px 0px;
  color: #fff;
}
#contactMe form input:focus, #contactMe form textarea:focus {
  outline: none;
}
#contactMe form textarea {
  min-height: 200px;
}
#contactMe form button {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  background: none;
  font-size: 1.3rem;
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  text-transform: uppercase;
}
#contactMe form button:hover {
  cursor: pointer;
  color: #ff5252;
  background: #fff;
}

footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

@media screen and (min-width: 850px) {
  #skills {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px 1fr;
  }
  #skills h2 {
    grid-row: 1/2;
    grid-column: 2/4;
  }
  #skills .skill-box {
    padding: 0px 20px;
  }
  #skills .skill-box h3 {
    text-align: center;
  }
  #skills .skill-box p {
    height: 250px;
  }
  #skills .skill-level {
    justify-content: center;
  }
  #skills .skill-level h4 {
    margin-right: 20px;
  }
  #skills .box1, #skills .box2 {
    grid-row: 2/3;
  }
  #skills .box1 {
    grid-column: 1/3;
  }
  #skills .box2 {
    grid-column: 3/5;
  }
}
@media screen and (min-width: 1200px) {
  .block {
    padding: 100px 10px 0px !important;
  }
  #projects {
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  #projects h2 {
    grid-row: 1/2;
    grid-column: 1/4;
  }
  #projects .project {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 250px 100px 250px;
    align-items: center;
    justify-items: center;
    padding: 0px 20px;
  }
  #projects .project h3 {
    text-align: center;
  }
  #projects .project .project-image {
    width: 200px;
    height: 200px;
  }
  #projects .project p {
    align-self: flex-start;
  }
}/*# sourceMappingURL=App.css.map */